import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import emailjs from 'emailjs-com';
import '../Style/Contacto.css'
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

function Contacto() {

  function enviarEmail(e){
    e.preventDefault();

    emailjs.sendForm('service_m28bk3u', 'template_j6fhpmb', e.target, '8jm0n0CvhQPv0pPsy' )
    .then((result) => {
      console.log();
  }, (error) => {
      console.log(error.text);
  });
  }

 return (
  <Container id='_Contacto' className='main-contact-from'>
    <Row className="justify-content-center">
      <Col lg="10" sm="12">

        <div className='contacto-titulo'>Contacto</div>
        <hr/>
        <Form onSubmit={enviarEmail}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Correo electrónico</Form.Label>
            <Form.Control type="email" placeholder="Correo Electrónico" id='Email' name='Email'/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Nombre</Form.Label>
            <Form.Control type="text" placeholder="Nombre" id='Nombre' name='Nombre' />
            <Form.Label>Apellido</Form.Label>
            <Form.Control type="text" placeholder="Apellido" id='Apellido' name='Apellido'/>
            <Form.Label>Dejanos tu mensaje</Form.Label>
            <Form.Control wrap='hard' as="textarea" type="text" id='message' name='message'/>
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Col>
    </Row>
  </Container>
  );
}

export default Contacto;