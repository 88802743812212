import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import '../Style/Service.css';

function Service(){
    return(
        <div className='service'>
           <Container id="_Servicios" fluid>
            <Row>
                <Col className='d-flex justify-content-center align-items-center descripción-producto' sm="12" lg="6">
                    <div className='descripción-producto-texto'>
                        <div className='title d-flex justify-content-center'>
                            Nuestros Servicios
                        </div>
                        <div className='body'>
                            <p className='SubTitle'>Servicio de Asesoramiento Energético:</p>
                            <p className='SubTitle-text'>Desarrollo y acompañamiento en procesos de toma de decisión en Mercados energéticos.  Planificación estratégica, gestión operativa, evaluación de mercado entre otras actividades de asesoramiento a la industria.</p>
                            <p className='SubTitle'>Gestión Energética Integral:</p>
                            <p className='SubTitle-text'>Modelos energéticos a la medida del cliente para la gestión eficiente de la empresa.</p>
                            <p className='SubTitle'>Eficiencia Energética:</p>
                            <p className='SubTitle-text'>Optimización de instalaciones, usos, contratos, equipamiento para lograr eficiencia energética.</p>
                        </div>
                    </div> 
                </Col>
                <Col className='d-flex flex-column justify-content-center align-items-center contacto-link' sm="12" lg="6">
                    <div className='contacto-link-text-container'>
                      <div className='contacto-link-text'>Contacta con nosotros</div>
                      <Button variant='primary' className=''>Click aqui</Button>
                    </div>
                </Col>
            </Row>
            </Container> 
        </div>
    )
}

export default Service;