import React, { useEffect }  from "react";
import Card from 'react-bootstrap/Card'
import "../Style/Objective.css"


import AOS from "aos";
import "aos/dist/aos.css";

function Objective(props){
    
	useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);


	return(
					<div data-aos={props.dataAos}>
        				<Card border={props.border} bg={props.variant} className={props.extraClassName + "objective-card"} md>
                    	  <Card.Body>
                    	    <Card.Title><div className="card-title-fake">{props.title}</div></Card.Title>
                    	    <Card.Subtitle className="mb-2 text-muted">{props.subtitle}</Card.Subtitle>
                    	    <Card.Text >
                                {props.children}
                    	    </Card.Text>
                    	  </Card.Body>
                    	</Card>
					</div>
    );
};

export default Objective;