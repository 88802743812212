import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../Style/AbautUs.css';

function AbautUs(){
    return(
        <div className='abautus'>
        	<Container fluid>
            <Row className="justify-content-sm-center">
                <Col sm="8" lg="6" className="abautus-text"><div className='text'>"Haciendo que el éxito suceda"</div><div className='geinsa'>-GEINSA</div></Col>
            </Row>
            
        	</Container>
        </div>
)}

export default AbautUs;