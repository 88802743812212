import React  from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import TeamCard from "./Team-card";

import "../Style/Team.css"

function Team(){

    return(
        <div className="Equipo" id="_Equipo">
            <div className="Equipo-titulo">Equipo</div>
            <Container fluid>
                <Row className="justify-content-between">
                    <TeamCard nombre="Nadia Sager" puesto=" GERENTE GENERAL: FUNDADORA" texto=" Ingeniera especializada en energías. Gestión Integral de mercados energéticos, planificación estratégica y operación.  Especializada en gas natural, mercados de energía eléctrica y energías renovables."></TeamCard>
                    <TeamCard nombre="Silvina De Micco" puesto="DESARROLLADORA: DATA SCIENCE" texto="Ingeniera en Materiales. Experiencia en desarrollo de modelado térmico de recipientes Siderúrgicos, análisis estadístico de variables de procesamiento y gestión de calidad en Tenaris. Actualmente trabajo en el desarrollo de modelos de machine learning. Data Science.
"></TeamCard>
                </Row>
                <Row>
                    <TeamCard nombre="Maria De Los Angeles Sager" puesto="RESPONSABLE EN EFICIENCIA ENERGÉTICA" texto="Ingeniera Civil. Apasionada por el Medio Ambiente, orientada a optimización de instalaciones, usos, contratos, equipamiento para lograr eficiencia energética en viviendas. Amplio conocimiento en metodología BIM, convencida de que el aprovechamiento de las herramientas tecnológicas puede lograr diseños aptos para el contexto energético y ambiental.
"></TeamCard>
                    <TeamCard nombre="Antonio Valentinuzzi" puesto="DESARROLLADOR: RESPONSABLE FRONT-END" texto="Estudiante de informatica y técnico electronico. Oriantado en conocimientos de programación Front-End. Actualmente formo parte del equipo de desarrollo de GEINSA."></TeamCard>

                </Row>
                <Row className="justify-content-center">
                    <TeamCard nombre="Ignacio Romero" puesto="DESARROLLADOR: RESPONSABLE BACK-END" texto="Estudiante de matematica y ciencias de la computacion ademas de tecnico electronico. Oriantado en conocimientos de programación back-End y actualmente formo parte del equipo de desarrollo de GEINSA."></TeamCard>
                </Row>
            </Container>
        </div>
    )
}

export default Team;