import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../Style/NavBar.css';
import LogoCompleto from '../img/Logo_Completo.png';
import Logo_G from '../img/Logo_G.png';
import { Link, animateScroll as scroll } from "react-scroll";



function NavBar(props) {

  const[logo, setLogo] = useState(window.innerWidth >= 992 ? LogoCompleto : Logo_G);

  function ChangeLogo(){
    setLogo(window.innerWidth >= 992 ? LogoCompleto : Logo_G);
  }

  window.addEventListener('resize', ChangeLogo )

	const scrollToTop = () => {
    scroll.scrollToTop();
	};

  return (
    
    <header className='header'>
      <div className='linea'></div>
      <Navbar collapseOnSelect expand="sm" className='navbar-color navbar'>
        <Container fluid>
          <Navbar.Brand onClick={scrollToTop} href="#home">
            <img
              alt="logo"
              src={ logo }
              className="logo"
            />{' '}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto links">
              <Nav.Link href=""> 
              <Link
              	activeClass="active"
              	to="section1"
              	spy={true}
              	smooth={true}
              	offset={-70}
              	duration={500}
              >Home</Link>
              </Nav.Link>
              <Nav.Link href="">
							<Link
              	activeClass="active"
              	to="_Objectives"
              	spy={true}
              	smooth={true}
              	offset={-40}
              	duration={100}
              >Sobre Nosotros</Link>
							</Nav.Link>
              <Nav.Link href=""><Link
              	activeClass="active"
              	to="_Servicios"
              	spy={true}
              	smooth={true}
              	offset={-130}
              	duration={100}
              >Servicios</Link></Nav.Link>
              <Nav.Link href=""><Link
              	activeClass="active"
              	to="_Equipo"
              	spy={true}
              	smooth={true}
              	offset={-70}
              	duration={100}
              >Equipo</Link></Nav.Link>
							<Nav.Link href=""><Link
              	activeClass="active"
              	to="_Contacto"
              	spy={true}
              	smooth={true}
              	offset={-70}
              	duration={80}
              >Contacto</Link></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default NavBar;