import React from "react";
import '../Style/Footer.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer(){
    return(
        <Container className="footer" fluid>
            <Row>
                <Col xs='6' sm='6' lg='6'>
                        Email: Geinsa.arg@gmail.com
                </Col>
                <Col xs = '6' sm='6' lg='6' className="d-flex flex-row-reverse">
                ©GEINSA
                </Col>
            </Row>
        </Container>
    )
}

export default Footer;