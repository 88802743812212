import React from "react";
import Objective from "./objective";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import LogoCompleto from '../img/Logo_Completo.png';

import"../Style/Objectives.css"
import FlipCard from "./FlipCard";


//Librerias de animación


function Objectives(){

	const cards = [
		{
			id: "1",
			variant: "hover",
			front: "Valores",
			back: ["COMPROMISO", "RESPONSABILIDAD","HONESTIDAD","CONFIDENCIALIDAD","APRENDIZAJE" ]
		},
		{
			id: "2",
			variant: "hover",
			front: "Misión",
			back: ["En GEINSA nos dedicamos a apoyar a las industrias y emprendimientos en sus gestiones energéticas para optimizar sus resultados, generando alianzas sustentables y buscando siempre las mejores alternativas.", "Contamos con vasta experiencia en mercados energéticos y de gas natural, con un equipo profesional dedicado a la innovación."]
			}
		]

		return(
				<div className="objectives">
						<Container className="objectives" fluid>
								<Row id="_Objectives" className="justify-content-sm-start justify-content-center objectives-row">
										<Col lg="6"className="logo-container objectives-col">
											<img className="logo-objective" src={LogoCompleto}  alt="Logo" />
										</Col>
										<Col lg="6" className="objectives-col">
											<div>
												<Objective border="light" variant="light" dataAos="fade-right" title="Sobre Nosotros">
													<p>
													Somos un grupo de emprendedores dedicado a apoyar a la industria, empresas y hogares en su gestión energética. Ampliamente inmersos en el mercado argentino y expandiendo nuestro alcance a nuevas regiones en el mundo.
													</p>
													<p>
													Nos dedicamos a asesorar, acompañar y desarrollar proyectos energéticos teniendo la perspectiva de los distintos actores y realizando alianzas estratégicas en beneficio del Cliente.
													</p>
													<p>Nuestro servicio es personalizado para otorgar soluciones eficientes y ambiciosas, no sólo buscando cumplir con lo pedido, sino asegurando éxito sostenible en el tiempo.</p>
													<p>En GEINSA hacemos que el éxito suceda.</p>
												</Objective>	
											</div>
										</Col>

								</Row>
								<Row className="justify-content-sm-start justify-content-center align-items-center  objectives-row">	
										
										<Col lg="6" className="objectives-col  justify-content-center d-flex">
											<FlipCard key={cards[0].id} card={cards[0]} />
										</Col>

										<Col lg="6" className="objectives-col justify-content-center d-flex">		
											<FlipCard key={cards[1].id} card={cards[1]} />
										</Col>

								</Row>
						</Container>
				</div>
		);
};

export default Objectives;