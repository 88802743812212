import NavBar from './components/NavBar';
import AbautUs from './components/AbautUs';
import Objectives from './components/Objectives';
import Service from './components/Service';
import Team from './components/Team';
import Contacto from './components/Contacto';

import "bootstrap/dist/css/bootstrap.min.css";
import Footer from './components/Footer';

function App() {

  return (
    <div className="App">
      <NavBar/>
      <AbautUs/>
      <Objectives/>
      <Service/>
      <Team/>
      <Contacto/>
      <Footer/>
    </div>
  );
}

export default App;
