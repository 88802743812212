import React from "react";
import Col from "react-bootstrap/Col";

import "../Style/TeamCard.css"
function TeamCard(props) {
    return (
                    <Col sm='12' lg='6'>
                        <div className="TeamCard-body-text d-flex justify-content-center">
                        <div className="TeamCard-nombre  d-flex justify-content-center">{props.nombre}</div>
                        <div className="TeamCard-puesto  d-flex justify-content-center">{props.puesto}</div>
                        <div className="TeamCard-texto  d-flex justify-content-center text-center">"{props.texto}"</div>
                        </div>
                    </Col>
    );
  }
  
  export default TeamCard;